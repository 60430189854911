(function () {
  'use strict';

  function error(err) {
    if (console && console.warn && console.log) {
      console.warn('Error within:', err.requireModules);
      console.log('Source:', err.source);
      console.log('Message:', err.message);
    }
  }

  require.config({
    baseUrl: 'js/',
    paths: {
      // Plugins require
      'async': 'vendor/requirejs-plugins/src/async',
      'text' : 'vendor/requirejs-plugins/lib/text',


      // Libs
      'jquery'        : 'vendor/jquery/jquery', // la bibliothèque [jQuery](http://jquery.com/).
      'lodash'        : 'vendor/lodash/lodash',
      'raphael'       : 'vendor/raphael/raphael',
      'modernizr'     : 'vendor/modernizr/modernizr.custom',

      'svg.vincent'   : 'modules/svg.vincent'
    },
    shim: {
      'jquery'        : {exports: 'jQuery'},
      'lodash'        : {exports: '_'},
      'modernizr'     : {exports: 'Modernizr'},
      'raphael'       : {exports: 'Raphael'},
      'svg.vincent'   : {exports: 'svgVincent'}
    },

    // Prevent cache
    urlArgs: 'bust=' + (new Date()).getTime(),
    waitSeconds: 0
  });

  require([
    'jquery',
    'modernizr',
    'raphael',
    'svg.vincent'
  ],
  function ($, Modernizr, Raphael, svgVincent) {


    Raphael($('.svg_vincent').get(0), 306, 378, svgVincent);
    
  },
  error);

})();
define("main", function(){});

